import React, { useContext } from 'react';
import { string, arrayOf, shape, number } from 'prop-types';

import { LayoutContext } from '../../context/layout';
import useCarouselBreakpoints from '../../../hooks/use-carousel-breakpoints';
import VehicleBodyItem from '../../vehicle-body-selector/vehicle-body-item';
import VehicleBodyViewMore from '../../vehicle-body-selector/vehicle-body-viewmore';

const VEHICLE_BODY_SELECTOR = 'VEHICLE_BODY_SELECTOR';

const namespace = 'ui-search-vehicle-body-selector';

const VehicleBodySelector = ({ intervention }) => {
  const { image, values, modal, total_body_types } = intervention;
  const { currentLayout } = useContext(LayoutContext);
  const { slidesToShow, viewMoreText } = useCarouselBreakpoints(
    VEHICLE_BODY_SELECTOR,
    currentLayout.id,
    total_body_types,
  );

  return (
    <div className={namespace}>
      <ul>
        {values
          .filter((_, index) => index < slidesToShow)
          .map((value) => (
            <VehicleBodyItem key={value.id} value={value} image={image} />
          ))}
        {viewMoreText && <VehicleBodyViewMore modal={modal} text={viewMoreText} />}
      </ul>
    </div>
  );
};

VehicleBodySelector.propTypes = {
  intervention: shape({
    image: string.isRequired,
    modal: shape().isRequired,
    total_body_types: number.isRequired,
    values: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
        url: string.isRequired,
      }),
    ).isRequired,
  }),
};

export default VehicleBodySelector;
